import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import Seo from "../components/Seo";

function ErrorPage() {
  return (
    <>
      <Seo title="404" />
      <Layout>
        <main className="error-page">
          <div className="error-container">
            <h1> Oups ! Cette page n’existe pas…</h1>
            <Link to="/" className="btn">
              Revenir a la page d'accueil
            </Link>
          </div>
        </main>
      </Layout>
    </>
  );
}

export default ErrorPage;
